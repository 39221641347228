<template>
  <v-card class="overflow-hidden" elevation="1" :height="cardheight">
    <v-card-text class="pa-4 py-8">
      <div class="d-flex">
        <div>
          <v-icon>mdi-account-circle</v-icon>
        </div>
        <div class="d-flex flex-column ml-2">
          <h5 class="text-capitalize">
            {{
              userData?.cliente?.nombre ??
              "" + " " + userData?.cliente?.primer_apellido ??
              "" + " " + userData?.cliente?.segundo_apellido ??
              ""
            }}
          </h5>

          <div class="d-flex">
            <h6 class="bold">Tipo doc.:</h6>
            <span class="h6 ml-2">{{
              userData?.cliente?.tipo_documento ?? ""
            }}</span>
          </div>

          <div class="d-flex">
            <h6 class="bold">N°:</h6>
            <span class="h6 ml-2">{{
              userData?.cliente?.numero_documento ?? ""
            }}</span>
          </div>

          <template>
            <!-- FECHA DE NACIMIENTO -->
            <div class="d-flex">
              <h6 class="bold">Fecha de nac.:</h6>
              <span class="h6 ml-2">
                {{
                  userData?.cliente?.fecha_nacimiento ?? "" | VMask(dateMask)
                }}
              </span>
            </div>
            <div class="d-flex">
              <!-- GENERO -->
              <h6 class="bold">Genero:</h6>
              <span class="h6 ml-2">{{
                genreList.find((e) => e.value === userData?.cliente?.genero)
                  .text
              }}</span>
            </div>
          </template>

          <div class="d-flex">
            <h6 class="bold">Teléfono:</h6>
            <span class="h6 ml-2">{{
              userData?.cliente?.datos_contacto?.telefono ?? ""
            }}</span>
          </div>
          <div class="d-flex">
            <h6 class="bold">Correo:</h6>
            <div class="h6 ml-2">
              {{ userData?.cliente?.datos_contacto?.email ?? "" }}
            </div>
          </div>
          <div class="d-flex">
            <h6 class="bold">Cliente desde:</h6>
            <span class="h6 ml-2">{{
              userData?.fecha_creacion ?? "" | formatDate
            }}</span>
          </div>
        </div>
      </div>
    </v-card-text>
    <v-card-actions v-if="editClient" class="justify-center px-8">
      <v-btn
        rounded
        color="primary"
        class="text-none"
        block
        outlined
        @click="editUserBtn()"
      >
        <v-icon left>mdi-pencil</v-icon>
        Editar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { dateMask } from "@/helpers/mask.js";
import { genreList } from "@/store/resources/constants.js";
export default {
  props: {
    userData: {
      type: [Object, Array],
      default: null,
    },
    editClient: {
      type: Boolean,
      default: true,
    },
    cardheight: {
      type: Number,
      default: 305,
    },
  },
  methods: {
    editUserBtn() {
      this.$emit("edit");
    },
  },
  computed: {
    dateMask: () => dateMask,
    genreList: () => genreList,
  },
};
</script>
